import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DirectionalLight, MeshBasicMaterial, Object3D, RGB_S3TC_DXT1_Format, XRWebGLLayer } from 'three'
import {gsap} from 'gsap'



/**
 * Base
 */
// Debug
const gui = new dat.GUI({closed:true})
gui.hide()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')


// Scene
const scene = new THREE.Scene()

/**
 * Fade 
 */
 const overlayGeo = new THREE.PlaneGeometry(2,2,1,1)
 const overlayMat = new THREE.ShaderMaterial({
     transparent:true,
     uniforms:
     {
         uAlpha: { value: 1 }
     },
     vertexShader: `
         void main()
         {
             gl_Position = vec4(position, 1.0);
         }
     `,
     fragmentShader: `
         uniform float uAlpha;
         
         void main()
         {
             gl_FragColor = vec4(0.11, 0.11, 0.11, uAlpha);
         }
     `
 })
 
 const overlay = new THREE.Mesh(overlayGeo,overlayMat)
 scene.add(overlay)
 

/**
 * Loaders 
 */

const loadingTextElement = document.querySelector('.loading')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMat.uniforms.uAlpha, { duration: 5, value: 0 })
            loadingTextElement.classList.add('ended')
            loadingTextElement.style.transform = ''
        }, 500)
    },
    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {   
        const progressRatio = itemsLoaded / itemsTotal
        loadingTextElement.textContent = progressRatio * 100 ;
        console.log(progressRatio*100)
        
    }
)
const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)


/**
 * Update all materials
 */
const updateMat = () => {
    scene.traverse((child)=>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            //child.material.envMap = envMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true 
            child.receiveShadow = true   
        } 
    })
}

scene.background = new THREE.Color(0x1E1E1E)
// scene.background = new THREE.Color(0xFFFFFF)

//debugObject.envMapIntensity = 1
//gui.add(debugObject,"envMapIntensity").min(0).max(10).step(0.001).onChange(updateMat)

/**
 * Models
 */

// Logo
gltfLoader.load(".//models/3dlogo_20210826_dm.glb",
 (gltf)=>
 {
    gltf.scene;
    gltf.scene.scale.set(0.19,0.19,0.19)
    //metallicaLogo.scale.set(10,10,10)
    gltf.scene.position.set(0,0,0)
    scene.add(gltf.scene) 
    updateMat();
 })

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)) 

     // Update effect composer
     effectComposer.setSize(sizes.width, sizes.height)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(111, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 0.7)
scene.add(camera)

// Cursor 
const cursor = {
    x:0,
    y:0
}

window.addEventListener('mousemove', (event) =>
{
    cursor.x = -(event.clientX / sizes.width - 0.5)
    cursor.y = event.clientY / sizes.height - 0.5
})


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    preserveDrawingBuffer: true,
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.LinearToneMapping
renderer.toneMappingExposure = +1
renderer.shadowMap.enabled = true
renderer.shadowMap.type =THREE.PCFShadowMap


gui.add(renderer, "toneMapping",{
        No:THREE.NoToneMapping,
        Linear:THREE.LinearToneMapping,
        Reinhard:THREE.ReinhardToneMapping,
        Cineon:THREE.CineonToneMapping,
        ACESF:THREE.ACESFilmicToneMapping
    })
.onFinishChange(()=>{
    renderer.toneMapping = Number(renderer.toneMapping)
    updateMat()
})

gui.add(renderer, "toneMappingExposure").min(0).max(10).step(0.001)


/**
 * Animate
 */
const clock = new THREE.Clock()

/**
 * Post-Processing
 */

 const renderTarget = new THREE.WebGLRenderTarget(
    800,
    600,
    {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        encoding: THREE.sRGBEncoding
    }
)
 
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio,2))
effectComposer.setSize(sizes.width, sizes.height)

const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

const smaaPass = new SMAAPass()
effectComposer.addPass(smaaPass)



const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()  

    // Update controls
    // controls.update()

    // Update Camera 
    camera.position.x = cursor.x *0.3
    camera.position.y = cursor.y *0.3
    camera.lookAt(0,0,0)

    // Render
    renderer.render(scene, camera)
  
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    // Render
    effectComposer.render()
}

tick()